import { i18n } from 'locales';

const radioGroupOptions = {
  contract: {
    label: i18n.t('hhb.expenses.customExpense.radioButtonLabel.contractLabel'),
    value: 'select',
  },
  expense: {
    label: i18n.t('hhb.expenses.customExpense.radioButtonLabel.expenseLabel'),
    value: 'text',
  },
};

export default radioGroupOptions;
