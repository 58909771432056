import analytics from '@dvag/dfs-accounting-analytics';
import { getLocale } from 'locales';

export const edit = 'edit';
export const split = 'split';

export const SPECIAL_PRODUCTS = ['151', '117'];

export const validationMessage = (maxAmount: number): string =>
  analytics.toCurrency(maxAmount, getLocale());
