import { initAccountingApi } from 'api';
import { Scenario, SimulationName, SimulationType } from '@store/simulation';
import { Person } from '@store/person';
import getDateDetails from '@utils/date/getMinAndMaxDate/getDateDetails';
import simpleDateFormat from '@utils/date/getMinAndMaxDate/simpleDateFormat';
import { i18n } from 'locales';

export const scenariosKey = {
  byHousehold: (householdId: string) => ['scenario-list', { householdId }],
};

export async function getScenariosQueryFn(householdId: string): Promise<Scenario[]> {
  return initAccountingApi()
    .get<Scenario[]>(`/simulation?householdId=${householdId}`)
    .then(({ data }) => data || []);
}
export async function updateScenario(body) {
  return initAccountingApi()
    .put<
      Scenario,
      {
        name: string;
        personId: string;
      }
    >(`/simulation/${body.id}`, {
      name: body.name,
      personId: body.personId,
    })
    .then(({ data }) => data);
}

export async function createScenario(
  person: Person,
  householdId: string,
  type = SimulationType.FUTURE_HOUSEHOLD_BUDGET,
) {
  const dateDetails = getDateDetails({ date: new Date() });
  const name = i18n.t('hhb.simulation.name', {
    type: SimulationName[type],
    firstName: person.firstName,
    lastName: person.lastName,
    date: simpleDateFormat(dateDetails),
  });
  return initAccountingApi().post<Scenario>(`/simulation`, {
    name,
    personId: person.id,
    type,
    householdId,
  });
}

export async function deleteScenario(id: string, personId: string) {
  return initAccountingApi().delete(`/simulation/${id}?&personId=${personId}`);
}

export default getScenariosQueryFn;
