import React, { FC } from 'react';

import { i18n } from 'locales';
import {
  DxText,
  DxDropdown,
  DxTextInput,
  DxNumberInput,
  DxDropdownOption,
} from '@dvag/design-system-react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormWatch,
  UseFormTrigger,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import useStore from '@store';
import { checkIsMobile } from 'utils';
import { currencyPlaceholder } from 'config';
import { edit, validationMessage } from './constants';

type OptionList = {
  value: string;
  label: string;
};
type PropType = {
  action: string;
  splittableAmount: number;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  displayGroupSplitOptionList: OptionList[];
  transactionTypesForValidation: string[];
};

const SplitProductColumn: FC<PropType> = (props) => {
  const {
    watch,
    action,
    errors,
    trigger,
    register,
    setValue,
    splittableAmount,
    displayGroupSplitOptionList,
    transactionTypesForValidation,
  } = props;
  const maxLength = 15;
  const transactionTypeNameKey = `transactionTypeName-${action}`;
  const amountAfterTaxesKey = `amountAfterTaxes-${action}`;
  const displayGroupIdKey = `displayGroupId-${action}`;
  const fieldMustBePositive = i18n.t('validation.fieldMustBePositive');

  const reservedTransactionTypeNames = useStore(
    (state) => state.transactionTypes.reservedTransactionTypeNames,
  );

  const isMobile = checkIsMobile();
  const transactionTypeErrorMessage = errors[transactionTypeNameKey]?.message;
  const amountFieldErrorMessage = errors[amountAfterTaxesKey]?.message;
  const ffgCategoryErrorMessage = errors[displayGroupIdKey]?.message;

  const firstViewIdx = 1;
  const secondViewIdx = 2;
  const isEditForm = action === edit;
  const index = isEditForm ? firstViewIdx : secondViewIdx;

  const amount = register(amountAfterTaxesKey, {
    max: {
      value: splittableAmount,
      message: i18n.t('validation.exceededMaximumValue', {
        maxValue: validationMessage(splittableAmount),
      }),
    },
    validate: {
      positive: (value) => value > 0 || fieldMustBePositive,
    },
    required: {
      value: true,
      message: i18n.t('validation.fieldRequired'),
    },
  });
  const ffgCategory = register(displayGroupIdKey, {
    required: {
      value: true,
      message: i18n.t('validation.fieldRequired'),
    },
  });
  const transactionType = register(transactionTypeNameKey, {
    required: {
      value: true,
      message: i18n.t('validation.fieldRequired'),
    },
    validate: (value: string) => {
      if (!isEditForm) {
        if (transactionTypesForValidation.findIndex((type: string) => type.toLowerCase() === value.trim().toLowerCase()) !== -1) {
          return i18n.t('validation.valueAlreadyExists', {
            value,
          });
        }
        if (reservedTransactionTypeNames.findIndex((type: string) => type.toLowerCase() === value.trim().toLowerCase()) !== -1) {
          return i18n.t('validation.valueIsReserved', {
            value,
          });
        }
      }


      if (!value.trim()) {
        return i18n.t('validation.valueMustExist', {
          value,
        });
      }
      return !!value.trim();
    },
  });

  const onChange = (value, name: string) => {
    setValue(`${name}-${action}`, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <div className="mb-6 flex" data-testid="split-product-column">
      <div className="w-full p-0">
        <div className="mb-4">
          <DxText
            type="ps-bold"
            color="gray-83"
          >{`${index}. ${i18n.t('hhb.splitProduct.partialAmount')}`}</DxText>
        </div>
        <DxNumberInput
          required
          ref={amount.ref}
          profile="currency-euro"
          limitlength={maxLength}
          className="currencyInput"
          disabled={isEditForm}
          placeholder={currencyPlaceholder}
          errormessage={amountFieldErrorMessage}
          label={i18n.t('hhb.splitProduct.amount')}
          value={watch(amountAfterTaxesKey)}
          data-testid={`split-amount-after-taxes-${index}`}
          onBlur={() => trigger(`amountAfterTaxes-${action}`)}
          onValueChange={(event) => {
            const value = event.detail;
            onChange(value as number, 'amountAfterTaxes');
          }}
        />
        <DxDropdown
          required
          label={i18n.t('hhb.splitProduct.ffgCategory')}
          placeholder={i18n.t('general.pleaseSelect')}
          disabled={isEditForm}
          value={watch(displayGroupIdKey)}
          data-testid={`display-group-id-${index}`}
          errormessage={ffgCategoryErrorMessage}
          className="mt-4"
          ref={ffgCategory.ref}
          onValueChange={(event) => {
            const value = event.detail;
            onChange(value, 'displayGroupId');
          }}
        >
          {displayGroupSplitOptionList.map((option) => (
            <DxDropdownOption key={option.value} value={option.value} label={option.label} />
          ))}
        </DxDropdown>
        <DxTextInput
          required
          label={i18n.t('hhb.splitProduct.expenseType')}
          value={watch(transactionTypeNameKey)}
          disabled={isEditForm}
          placeholder={i18n.t('general.pleaseSelect')}
          ref={transactionType.ref}
          onValueChange={(event) => {
            const value = event.detail;
            onChange(value, 'transactionTypeName');
          }}
          errormessage={transactionTypeErrorMessage}
          className="mt-4"
          data-testid={`expense-type-input-${index}`}
        />
      </div>
      {isEditForm && !isMobile && (
        <div
          data-testid="separator"
          className="mb-0 ml-10 mr-6 mt-8 border-0 border-l border-solid border-l-gray-87"
        />
      )}
    </div>
  );
};

export default SplitProductColumn;
