import { immer } from 'zustand/middleware/immer';
import { ExpensesSlice } from './type';

const createExpensesSlice = immer<ExpensesSlice>((set) => ({
  expenses: {
    isSubmittingContract: {},
    showSurplus: false,
    isShowSurplusButtonDisabled: false,
    actions: {
      setIsSubmittingContract: (isSubmitting, contractId) =>
        set((draftState) => {
          draftState.expenses.isSubmittingContract[contractId] = isSubmitting;
        }),
      setShowSurplus: (value: boolean) =>
        set((draftState) => {
          draftState.expenses.showSurplus = value;
        }),
      setIsShowSurplusButtonDisabled: (value: boolean) =>
        set((draftState) => {
          draftState.expenses.isShowSurplusButtonDisabled = value;
        }),
    },
  },
}));

export default createExpensesSlice;
