import { AddExpenseVariantEnum } from 'screens/householdBudget/expenses/config';
import { DisplayGroupTypesEnum } from 'utils';

const findAddExpenseVariant = (displayGroupKey) => {
  const contractDropdownVariant = [DisplayGroupTypesEnum.Schutzengel, DisplayGroupTypesEnum.Sparen];
  if (contractDropdownVariant.indexOf(displayGroupKey) !== -1) {
    return AddExpenseVariantEnum.ContractDropdown;
  }

  if (displayGroupKey === DisplayGroupTypesEnum.Lebenundconsum) {
    return AddExpenseVariantEnum.ContractDropdownAndCustomExpense;
  }

  return AddExpenseVariantEnum.CustomExpense;
};

export default findAddExpenseVariant;
